<template>
  <div>
    <v-card width="800px" outlined style>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{language.userVerification.userDetails}}</v-toolbar-title>
      </v-toolbar>
      <v-spacer></v-spacer>
      <form
        ref="userDetails"
        action="serve.php?f=administration&f2=superAdminUserVerification"
        method="post"
      >
          <fieldsTds
                  :field="'n_id'"
                  :fieldAttributes="result.fields.n_id"
                  :templateContent="result"
                  :value="valueLine.n_id"
          ></fieldsTds>
          <fieldsTds
                  :field="'n_id_key'"
                  :fieldAttributes="result.fields.n_id_key"
                  :templateContent="result"
                  :value="valueLine.n_id_key"
          ></fieldsTds>
        <v-list-item>
          <v-list-item-content class="pl-12">
              <v-row>
                <v-col>
                    <v-row>
                        <v-col cols="3" class="subtitle-2">{{language.userVerification.profilePix}}</v-col>
                        <v-col>
                            <avatar
                                    :result="avatarResult"
                                    :edit=false
                                    :textName=fullName
                            ></avatar>
                        </v-col>
                    </v-row>
                  <v-row>
                    <v-col cols="3" class="subtitle-2">{{language.userVerification.fName}}</v-col>
                    <v-col>
                      <fieldsTds
                        :field="'c_firstname'"
                        :fieldAttributes="result.fields.c_firstname"
                        :templateContent="result"
                        :value="valueLine.c_firstname"
                      ></fieldsTds>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="subtitle-2">{{language.userVerification.lName}}</v-col>
                    <v-col>
                      <fieldsTds
                        :field="'c_lastname'"
                        :fieldAttributes="result.fields.c_lastname"
                        :templateContent="result"
                        :value="valueLine.c_lastname"
                      ></fieldsTds>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="subtitle-2">{{language.userVerification.email}}</v-col>
                    <v-col>
                      <fieldsTds
                        :field="'c_email'"
                        :fieldAttributes="result.fields.c_email"
                        :templateContent="result"
                        :value="valueLine.c_email"
                      ></fieldsTds>
                    </v-col>
                  </v-row>
                  <!--    <v-row>
                                    <v-col>
                                        Company
                                    </v-col>
                                    <v-col>
                                        <fieldsTds
                                                :field="'c_company'"
                                                :fieldAttributes="result.fields.c_company"
                                                :templateContent="result"
                                                :value="valueLine.c_company"
                                        ></fieldsTds>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        Job
                                    </v-col>
                                    <v-col>
                                        <fieldsTds
                                                :field="'c_jobtype'"
                                                :fieldAttributes="result.fields.c_jobtype"
                                                :templateContent="result"
                                                :value="valueLine.c_jobtype"
                                        ></fieldsTds>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        Industry
                                    </v-col>
                                    <v-col>
                                        <fieldsTds
                                                :field="'c_industry'"
                                                :fieldAttributes="result.fields.c_industry"
                                                :templateContent="result"
                                                :value="valueLine.c_industry"
                                        ></fieldsTds>
                                    </v-col>
                                </v-row>-->
                  <v-row>
                    <v-col cols="3" class="subtitle-2">{{language.userVerification.note}}</v-col>
                    <v-col>
                      <fieldsTds
                        :field="'c_note'"
                        :fieldAttributes="result.fields.c_note"
                        :templateContent="result"
                        :value="valueLine.c_note"
                      ></fieldsTds>
                    </v-col>
                  </v-row>
                </v-col>
                <!--<v-col>
                  <v-row>
                    <v-col class="">
                      <avatar :result="valueLine.avatar.url"></avatar>
                    </v-col>
                  </v-row>
                </v-col>-->
              </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-expansion-panels hover tile focusable class="mb-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="subtitle-2">{{language.userVerification.contactDetails}}</v-expansion-panel-header>
            <v-expansion-panel-content class="pl-12">
              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.phone}}</v-col>
                <v-col>
                  <fieldsTds
                    :field="'c_phone'"
                    :fieldAttributes="result.fields.c_phone"
                    :templateContent="result"
                    :value="valueLine.c_phone"
                  ></fieldsTds>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.address}}</v-col>
                <v-col>
                  <fieldsTds
                    :field="'c_street1'"
                    :fieldAttributes="result.fields.c_street1"
                    :templateContent="result"
                    :value="valueLine.c_street1"
                  ></fieldsTds>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.street}}</v-col>
                <v-col>
                  <fieldsTds
                    :field="'c_street2'"
                    :fieldAttributes="result.fields.c_street2"
                    :templateContent="result"
                    :value="valueLine.c_street2"
                  ></fieldsTds>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.zip}}</v-col>
                <v-col>
                  <fieldsTds
                    field="c_zip"
                    :fieldAttributes="result.fields.c_zip"
                    :templateContent="result"
                    :value="valueLine.c_zip"
                  ></fieldsTds>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.city}}</v-col>
                <v-col>
                  <fieldsTds
                    field="c_city"
                    :fieldAttributes="result.fields.c_city"
                    :templateContent="result"
                    :value="valueLine.c_city"
                  ></fieldsTds>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="subtitle-2">{{language.userVerification.country}}</v-col>
                <v-col>
                  <fieldsTds
                    field="c_country"
                    :fieldAttributes="result.fields.c_country"
                    :templateContent="result"
                    :value="valueLine.c_country"
                  ></fieldsTds>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels hover tile focusable class="mb-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="subtitle-2">{{language.userVerification.loginHistory}}</v-expansion-panel-header>
            <v-expansion-panel-content class="pl-9 pt-3">
                <v-row style="background-color:#8080803d;">
                    <v-col>{{language.userVerification.lTime}}</v-col>
                    <v-col>{{language.userVerification.ip}}</v-col>
                    <v-col>{{language.userVerification.bv}}</v-col>
                    <v-col>{{language.userVerification.os}}</v-col>
                </v-row>
              <v-row style="overflow-y: scroll; max-height: 350px;">
                <v-col>
                  <v-row
                    v-for="(fieldsInfo, field) in valueLine.c_userLoginHistory"
                    :key="field"
                  >
                    <v-col>{{ loginTime(fieldsInfo.n_loginTime) }}</v-col>
                    <v-col>{{ fieldsInfo.c_ip }}</v-col>
                    <v-col>{{ fieldsInfo.c_browser }}</v-col>
                    <v-col>{{ fieldsInfo.c_os }}</v-col>
                  </v-row>
                 </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels hover tile focusable class="mb-3">
          <v-expansion-panel>
              <v-expansion-panel-header><span class="subtitle-2 mr-2" style="max-width:fit-content;">{{language.userVerification.ac}}: </span> {{result.valueLine.c_adminNote!=''?result.valueLine.c_adminNote:'No comments yet!'}}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
  <!--            <v-row>
                <v-col>
                  <fieldsTds
                    field="n_active"
                    :fieldAttributes="result.fields.n_active"
                    :templateContent="result"
                    :value="valueLine.n_active"
                    @input="checkInput('n_active', ...arguments)"
                  ></fieldsTds>
                </v-col>
              </v-row>-->
              <v-row>
                <v-col>
                  <fieldsTds
                    field="c_adminNote"
                    :fieldAttributes="result.fields.c_adminNote"
                    :templateContent="result"
                    :value="valueLine.c_adminNote"
                    @input="checkInput('c_adminNote', ...arguments)"
                  ></fieldsTds>
                </v-col>
              </v-row>
           <!--   <v-row>
                <v-col>
                  <fieldsTds
                    field="c_noteToUser"
                    :fieldAttributes="result.fields.c_noteToUser"
                    :templateContent="result"
                    :value="valueLine.c_noteToUser"
                  ></fieldsTds>
                </v-col>
              </v-row>-->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="pa-9"></div>
        <v-card-actions
          class="max-auto userVerificationDialog"
        >
          <fieldsButtons
            :result="result"
            @submitted="submitForm(...arguments)"
          ></fieldsButtons>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>
<script>
//import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
import fieldsTds from "@/commonComponents/fieldsTds.vue";
import avatar from "@/components/legacy/avatar.vue";

export default {
  components: {
    avatar,
    fieldsTds,
    fieldsButtons,
    //  fieldsSingleRows
  },
  data: function () {
    return {
      dialog: false,
      fullName: this.result.fields.c_firstname.value+" "+this.result.fields.c_lastname.value
    };
  },
  computed: {
    valueLine() {
      return this.result.valueLine;
    },
    avatarResult(){
      let url ="";
      if(typeof this.valueLine.avatar!="undefined" &&
         typeof this.valueLine.avatar.url!="undefined" &&
         this.valueLine.avatar.url!=""
      ){
         url = this.valueLine.avatar.url;
      }
      return url;
    }
  },
  methods: {
    checkInput(field, value) {
      //console.log("--->", field, value);
      //this.$set(this.result.fields[field], "value", value);
      // this.$emit("dialog-result-changed", this.result);
      if (typeof this.result.valueLine == "undefined") {
        this.$set(this.result, "valueLine", {});
      }
      this.$set(this.result.valueLine, field, value);
      //console.log("dialog-result-changed", this.localResult);
      this.$emit("dialog-result-changed", this.result);
    },
    submitForm(functionName) {
      this.ajaxSubmit(
        functionName,
        this.$refs.userDetails,
        this.$refs.userDetails.action,
        this.result
      );
      this.$notify({
        group: "foo",
        text: "Successfully Updated!!",
        duration: 1000,
        type: "success",
        speed: 600,
      });
    },
    loginTime(timeStamp) {
      var date = new Date(timeStamp * 1000);
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var dateString = date.toLocaleDateString("en-US");
      return (
        dateString +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2)
      );
    },
  },
  props: ["row", "currentTable", "result"],
};
</script>