<template>
    <v-avatar
            tile
            size="80"
            class="rounded"
            @click="callAvatar()"
    >
        <img v-if="avatarURL!='textAvatar'"
            class="profileImg grey lighten-2 rounded-x3"
            :src="avatarURL"
            title="Gravatar Photo from your Gravatar Account"
            style="cursor: pointer;"
        >
       <v-avatar v-else
                 color="primary"
                 class="textAvatar profileImg">
           <span
                class= "white--text"
                :style="fontSize"
                title="Text Avatar"
        >{{avText(textName)}}</span>
    </v-avatar>
        <v-icon v-if="edit"
            class="editButt"
            color="white"
    >
        mdi-account-edit-outline
    </v-icon>
    </v-avatar>
</template>
<script>
  import {getAvatarText} from "@/js/helper.js";

  export default {
    data:function(){
      return {
        avatarURL: this.result,
        fontSize: {
          fontSize: typeof this.fSize!="undefined"?this.fSize+"px":"16px"
        }
    };
    },
    methods:{

      avText:function(str){
        return getAvatarText(str);
      },
      callAvatar:function(){
        window.open( "https://en.gravatar.com","_blank");
      }
    },
    props: ["result","edit","textName","fSize"]

  }
</script>
<style scoped>
    .editButt {
        position: absolute !important;
        top: -25px;
        left: 28px;
        font-size: 16px;
    }
    .editButt:hover {
        cursor: pointer;
    }
    .textAvatar{
        height: inherit !important;
        min-width: inherit !important;
        width: inherit !important;
    }
    .profileImg{
        background-color: rgba(159, 176, 202, 0.6) !important;
        border: 1px solid #021a40 !important;
        height: 80px !important;
        padding: 1px !important;
        width: 80px !important;
        border-radius: 5px;
    }
</style>